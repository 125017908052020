






























import VHelpIcon from '@/components/VHelpIcon.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  BDropdown,
  BDropdownHeader,
  BDropdownDivider,
  BDropdownItemButton,
} from 'bootstrap-vue';

@Component({
  components: { BDropdown, BDropdownDivider, BDropdownHeader, BDropdownItemButton, VHelpIcon },
})
export default class VDropdown extends Vue {
  @Prop({ default: true }) private noFlip!: boolean;
  @Prop ({ default: false }) private search!: boolean;
  @Prop ({ default: false }) private disabled!: boolean;
  @Prop ({ default: false }) private loading!: boolean;
  @Prop ({ default: null }) private state!: boolean;
  @Prop({ default: [] }) private options!: Array<{ text: string, value: any, disabled: boolean }>;
  @Prop({ required: true }) private dropdownId!: string;
  @Prop({ default: 'Kiezen...' }) private placeholderText!: string;
  @Prop({ default: '' }) private label!: string;
  @Prop({ default: '' }) private helpText!: string;
  @Prop({ default: null }) private value!: string | null;

  private searchString = '';
  private selected: { text: string, value: any } = this.options.find((option) => option.value === this.value) ?? { text: '', value: null };

  private focusSearch() {
    (this.$refs[this.dropdownId + '-search'] as any).$el.focus();
  }

  get isDisabled() {
    return this.disabled;
  }

  get searchedOptions() {
    if (!this.search) {
      return this.options;
    }
    const trimmedSearchText = this.searchString.trim().toLowerCase();
    return this.options.filter((option) => {
      return option.text.toLowerCase().includes(trimmedSearchText);
    });
  }

  @Watch('value')
  private handleValue(value: any) {
    if (value) {
      this.selected = this.options.find((option) => option.value.toString() === value.toString()) ?? { text: '', value: null };
    }
  }

  @Watch('options')
  private handleOptions(val: Array<{ text: string, value: any}>, oldVal: any) {
    if (val.length === 1) {
      this.selected = val[0];
    } else {
      this.selected =  { text: '', value: null };
    }
    this.handleValue(this.value);
  }
}
